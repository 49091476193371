import {
  startAuthentication,
  startRegistration,
} from "@simplewebauthn/browser";
import type {
  generateAuthenticationOptionsInputSchema,
  generateRegistrationOptionsInputSchema,
  loginInputSchema,
  registerInputSchema,
} from "~/validations/auth";

export async function register(input: input<typeof registerInputSchema>) {
  const { phone, name, device, address, storeName, type: storeType } = input;

  const registrationOptions = await $fetch(
    "/api/auth/generate-registration-options",
    {
      method: "POST",
      body: { phone, name, device } satisfies input<
        typeof generateRegistrationOptionsInputSchema
      >,
    },
  );

  let registrationResponse;
  try {
    registrationResponse = await startRegistration({
      optionsJSON: registrationOptions,
    });
  } catch (error) {
    if (error instanceof Error) {
      if (error.name === "InvalidStateError") {
        throw createError({
          statusCode: 400,
          message: "Authenticator was probably already registered by user",
        });
      } else {
        throw createError(error);
      }
    }
  }

  try {
    await $fetch("/api/auth/verify-registration", {
      method: "POST",
      body: {
        ...registrationResponse,
        phone,
        name,
        address,
        storeName,
        storeType,
      },
    });
  } catch (error) {
    if (error instanceof Error) throw createError(error);
  }
}

export async function login(input: input<typeof loginInputSchema>) {
  const { phone } = input;

  try {
    const athenticationOptions = await $fetch(
      "/api/auth/generate-authentication-options",
      {
        method: "POST",
        body: { phone } satisfies input<
          typeof generateAuthenticationOptionsInputSchema
        >,
      },
    );

    const authenticationResponse = await startAuthentication({
      optionsJSON: athenticationOptions,
    });

    await $fetch("/api/auth/verify-authentication", {
      method: "POST",
      body: { ...authenticationResponse, phone },
    });
  } catch (error) {
    if (error instanceof Error) throw createError(error);
  }
}
